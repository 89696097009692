export default {
	pageTitle: 'Fintech | Department for International Trade',
	pageDescription: `How open banking is revolutionising the UK banking sector`,
	heroSectionOne: {
		main: {
			title: 'Powering fintech innovation',
			author: 'WRITTEN BY',
			brandPath: 'images/EBCONPRGB.svg',
			content: `
			<p>
				How open banking is revolutionising the UK banking sector
			</p>
		`
		},
		banner: {
			firstImg: 'images/fintech_new.jpg',
			secondImg: 'images/svg/square-gradient.svg'
		}
	},
	sliderSectionOne: {
		headerImgPath: 'images/innovation-is-great.png',
		headerImgAlt: 'Innovation is great img alt',
		transparentBg: true,
		settings: {
			dots: false,
			arrows: false,
			infinite: false,
			speed: 500,
			slidesToShow: 3,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 991,
					settings: {
						slidesToShow: 2.2,
						slidesToScroll: 1,
						draggable: true
					}
				}, {
					breakpoint: 767,
					settings: {
						slidesToShow: 1.2,
						slidesToScroll: 1,
						draggable: true,
						arrows: true
					}
				}
			]
		},
		items: [
			{
				img: 'images/home_smart_cities.jpg',
				title: 'Smart cities',
				text: 'From nuclear fusion and smart buildings to electric vehicles, UK companies are spearheading the ' +
					'cleantech revolution.',
				more: {
					label: 'Read more',
					link: '/story/smart-cities/'
				}
			}, {
				img: 'images/home_edtech.jpg',
				title: 'Edtech',
				text: 'Education was once lagging in digital innovation, but this is all changing. UK-based edtech startup Twig ' +
					'Education is blending learning and technology to bring subjects to life.',
				more: {
					label: 'Read more',
					link: '/story/edtech/'
				}
			}, {
				img: 'images/home_cybersecurity.jpg',
				title: 'Cybersecurity',
				text: 'The rise in cyberattacks and identity fraud has made managing our digital identities increasingly challenging. ' +
					'But a wave of UK startups and SMEs is helping to raise awareness in the corporate community.',
				more: {
					label: 'Read more',
					link: '/story/cybersecurity/'
				}
			}
		]
	},
	cardSectionOne: {
		position: 'left',
		bgImg: 'images/svg/square-gradient.svg',
		main: {
			title: '',
			content: `
			<p style="margin-bottom: 35px">Fintech is among the UK’s strongest brands today, from digital challenger banks such as Revolut, Starling and 
			Monzo, through to niche segments including peer-to-peer lending (Crowdcube), wealth management (Nutmeg) and payments 
			(Checkout.com). UK fintech attracted 
			<a target="_blank" href="https://www.innovatefinance.com/news/the-uk-retains-its-crown-as-europes-capital-for-fintech-investment/">$4.1bn</a> 
			in venture capital in 2020, with mega-funding rounds of £100m or more for the likes of Revolut and Onfido, a 
			biometric ID start-up.</p>
			<p>UK fintech is partly built on history, specifically London’s dominance as a global financial centre. The city, 
			which straddles US and Asian time zones, offers “deep pools of talent and capital, and familiar and easily accessible 
			business structures, legal frameworks and professional services,” according to Philippa Martinelli, fintech 
			specialist and advisor to the Department for International Trade (DIT).</p>`
		},
		additional: {
			img: 'images/story_1.jpg',
			comment: {
				text: 'The epicentre of fintech is London and being here puts us in the best possible position, including from ' +
					'a fundraising perspective.',
				subtext: 'Matty Cusden-Ross, founder and CEO of Flux'
			}
		},

	},
	cardSectionTwo: {
		position: 'left',
		bgImg: 'images/svg/square-gradient.svg',
		main: {
			title: 'Trade and investment challenges and opportunities in the post-pandemic world',
			content: `<p>The pandemic has transformed the global trade and investment landscape, but offers unique 
			opportunities to agile and resilient businesses in sectors of the future.</p>`
		},
		additional: {
			img: 'images/chart_big.jpg',
		},
		more: {
			label: 'Read More',
			link: '/briefing-paper/'
		}
	},
	contentOne: `
		<p>
			London accounts for 43% of the turnover of foreign exchange and handles half of the daily global interest rate
			 derivatives markets. London-based funds manage £8.5trn in assets. There are an estimated 1.1m people working in 
			 financial and related services in the UK, two-thirds of whom are outside London, and more than 2m in the UK tech 
			 sector. 
		</p>
		<p>
		Out of the wreckage of the financial crisis, a generation of fintechs emerged looking to take the opportunities 
		offered by digital innovation, from frictionless transactions and personalised services to a financial sector that 
		had seen too little innovation for the retailer customer. East London’s ‘Silicon Roundabout’, proximate to the 
		financial district, was later designated Tech City (2011) by the UK government under David Cameron, which invested 
		£50m to support the startup ecosystem.
		</p>
		<p>
		But it was forward-looking legislation by the UK government that truly unlocked today’s fintech community. The UK 
		Financial Conduct Authority was formed in 2013 with an expanded mandate that includes promoting competition and 
		consumer choice, leading eventually to the 2018 open banking law. Unusually, this required the country’s nine 
		largest banks to create open-source application programming interfaces (APIs) to enable third parties to offer 
		a panoply of applications, from low-cost accounting, budgeting and money management tools to investment platforms. 
		This gave fintechs opportunities that went far beyond the confines of banking apps. 
		</p>
		<p>
		“It was the advent of open banking that really accelerated the ability of big banks to partner with fintechs 
		like us,” says Matty Cusden-Ross, founder and CEO of Flux, a digital receipt startup.“The epicentre of fintech 
		is London and being here puts us in the best possible position, including from a fundraising perspective. We can 
		propagate the message far and wide, and I am always amazed at the quality of candidates we can hire, not only in 
		our area but also tangential sectors that can make us stronger.”
		</p>
		<p>
		Flux’s business captures the vast datasets buried in paper receipts and often unaccessed. “Receipts are used 
		everywhere, from expenses and budgeting to nutritional information, but they have been hard to access until 
		now,” says Mr Cusden-Ross. By capturing spending at a highly granular level from opted-in customers, Flux 
		aggregates receipt data in ways that enable merchants such as KFC and Just Eat to understand their customers. 
		They can then design targeted offers on Flux’s platform and loyalty schemes. It can also tell them the 
		balance of new versus returning customers and changes in purchasing behaviour.
		</p> 
		<p>
		Banks are Flux’s second commercial partner, with current clients including Barclays, Monzo and Starling. By giving 
		them richer transaction data, they can run offers and partnerships with retailers and offer an enriched user 
		experience by helping customers understand and track spending habits. It’s an effective model: one bank saw a 63% 
		increase in app engagement working with Flux. The open banking reforms have led incumbent financial institutions 
		in the UK to shift towards a more pro-innovation mindset compared with those in many other overseas markets, says 
		Mr Cusden-Ross. 
		</p>
		<p>
		Flux has now processed £3bn across 35,000 locations in the UK. “Increasingly, people in the UK prefer to transact in 
		a cashless way. Business models like Flux are jumping on this wider trend towards open banking,” says Sameer Gulati,
		 fintech specialist at DIT.
		</p>
	`,
	contentTwo: `
		<p>
			<span>A virtuous circle</span>
		</p>
		<p>
			Innovation clusters tend to strengthen their dominance over time as talent and capital flocks in. For open banking, 
			UK fintechs are a self-reinforcing community with high levels of startup partnerships that enable concepts to be 
			proven and scaled. This in turn enables them to be road-tested to a level that means larger mainstream banks become 
			comfortable experimenting. 
		</p>
		<p>
			Mr Cusden-Ross explains that, in its early days, Flux’s customers were challenger banks because they were nimbler 
			and more pro-experimentation and pro-innovation. That meant  Flux could test and prove its concept before engaging 
			with large banks that have been historically unaccustomed to working with startups. “When we began, we started 
			working with challenger banks and that enabled us to prove what we did quickly. If they weren’t there, it would 
			have been a harder journey to work with the incumbents.” As the tech gained traction, larger players noticed. 
			“Combined with open banking and the shift of banks to partnering more with startups, this led to relationships we 
			have now with the likes of Barclays.”  
		</p>
		<p>
			While the Silicon Valley narrative is of startups ‘disrupting’ incumbents, financial services is a more reciprocal 
			affair. The reality is that startups and large banks need each other. “Convincing a consumer to move all their 
			banking to a fintech can be challenging,” says the DIT’s Ms Martinelli. “In reality, the bank is often the core 
			relationship holder, but thanks to fintech the consumer can now plug in all of these value-added services around 
			their account. A lot of the discussion between fintechs and banks has become, ‘How do we partner and collaborate?’ 
			rather than fintechs saying, ‘Step out of the way, we want to tackle the whole of financial services ourselves.’”
		</p>
		<p>
			<span>Building trading opportunities</span>
		</p>
		<p>
			A big question for the UK fintech community now is how to take their innovations global, as more and more consumers 
			and governments look to support the sector thanks to its wide-ranging benefits for financial inclusion and economic 
			dynamism. Digital payments have been essential to enabling economies to keep functioning over the past year, for instance.  
		</p>
		<p>
			“The market has changed so much globally over the past five to ten years,” says Mr Gulati. “The UK was one of a 
			handful of countries with a strategic focus on fintech with trade bodies, sector reviews and government support. 
			Now, everywhere from India and China to sub-Saharan Africa and the Gulf region, countries are putting fintech high 
			up their national agenda and positioning themselves as centres of excellence.” 
		</p>
		<p>
			The DIT is working to help UK fintechs access international opportunities and keep the country at the cutting edge 
			of innovation. <a target="_blank" href="https://www.gov.uk/government/news/tech-for-growth-designed-to-increase-financial-services-access?utm_source=economist&utm_medium=display&utm_content=article-fintechgrowth&utm_campaign=rtt">
			The Tech for Growth initiative</a>, launched in July 2020, has set out objectives to establish a UK-Africa 
			tech community to expand access to financial services. Likewise, <a target="_blank" href="https://www.great.gov.uk/campaigns/support-for-fintechs/?utm_source=economist&utm_medium=display&utm_content=article-fintechpartnerships&utm_campaign=rtt">
			DIT’s Leading Edge Global Partnerships Programme</a> 
			is aimed at supporting fast-growing UK fintechs with the platform to advance commercial dialogue with international 
			financial institutions. “This builds upon our bilateral fintech partnerships such as Fintech Bridges and the FIP. 
			In the long run, it helps to ensure fintech plays a core role in the UK’s trading relationships abroad,” says Mr Gulati.   
		</p>
	`,
	additionalBottomText:
		`<p>Produced for DIT by (E) BrandConnect, a commercial division of The Economist Group, which operates 
		separately from the editorial staffs of The Economist and The Economist Intelligence Unit. Neither (E) BrandConnect 
		nor its affiliates accept any responsibility or liability for reliance by any party on this content.</p>`
}
